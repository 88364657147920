<template>
  <div class="pageContol">
    <!-- 列表部分 -->
    <div class="framePage">
      <!-- 页面位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">问卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 操作区 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">问卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入问卷名称"
                clearable
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="addPaper()"
              >新建问卷</el-button
            >
          </div>
        </div>

        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="问卷名称"
                align="left"
                prop="paperName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="问卷描述"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.paperDescription || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.compName || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="来源"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.source || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="是否发布"
                align="center"
                show-overflow-tooltip
                prop="published"
                width="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-switch
                    :disabled="scope.row.source != '平台'"
                    class="selStyle"
                    v-model="scope.row.published"
                    width="40"
                    @change="getSwitch(scope.row.paperId, scope.row.published)"
                  ></el-switch>
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="200px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="showPaper(scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    v-if="scope.row.source == '平台'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="deletePaper(scope.row.paperId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>

        <!-- 分页 -->
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 新增 -->
    <el-dialog
      title="新增问卷"
      :visible.sync="dialogFormVisible"
      width="50%"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
      >
        <el-form-item label="问卷名称：" label-width="100px" prop="paperName">
          <el-input
            v-model="groupForm.paperName"
            size="small"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          label="问卷描述："
          label-width="100px"
          prop="paperDescription"
        >
          <el-input
            type="textarea"
            v-model="groupForm.paperDescription"
            size="small"
            maxlength="1200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/Testquestionbank",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compId: "",       // 机构id
      CompanyList: [],  // 机构列表
      compName: "",     // 机构名称
      paperName: "",    // 问卷名称
      dialogFormVisible: false,
      groupForm: {
        paperName: "",
        paperDescription: "",
      },
      rules: {
        paperName: [
          { required: true, message: "请输入问卷名称", trigger: "blur" },
        ],
        paperDescription: [
          { required: true, message: "请输入问卷描述", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.paperName) {
        params.paperName = this.paperName;
      }

      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/survey/sr-paper/pageList",
        params,
        pageNum,
      });
    },
    addPaper() {
      this.dialogFormVisible = true;
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            paperName: this.groupForm.paperName || "",
            paperDescription: this.groupForm.paperDescription || "",
          };
          this.$post("/survey/sr-paper/insert", params).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData(-1);
            }
          });
        }
      });
    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    getSwitch(paperId, published) {
      this.$post("/survey/sr-paper/published", { paperId, published })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    deletePaper(paperId) {
      this.doDel({
        url: "/survey/sr-paper/deletePaper",
        msg: "你确定要删除该问卷吗？",
        ps: {
          type: "post",
          data: { paperId },
        },
      });
    },
    showPaper(row) {
      let paperId = row.paperId
      let source = row.source
      
      this.$router.push({
        path: "/web/operate/questionnaireEdit",
        query: {
          paperId,
          source
        },
      });
    },
    /* 导出 */
    exportPaper(paperId) {
      this.$post("/biz/paper/exportPaper", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    /* 导出 - word */
    exportWord(paperId) {
      this.$post("/biz/paper/exportPaperWord", {
        paperId,
      })
        .then((ret) => {
          window.open(ret.data);
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.el-textarea__inner {
  min-height: 20rem !important;
  resize: none;
}
</style>
